<template>
  <div class="container px-4 lg:px-8">
    <div class="flex justify-between items-center lg:items-start">
      <div class="flex gap-x-3">
        <hamburger class="lg:hidden" />
        <nuxt-link
          to="/"
          aria-label="Examenbundel"
        >
          <q-svg name="examenbundel-logo" />
        </nuxt-link>
      </div>
      <desktop-navigation class="hidden lg:block" />
      <div class="flex space-x-4">
        <div class="flex md:space-x-4">
          <e-button
            tag="nuxt-link"
            to="/zoeken"
            aria-label="Zoeken"
            type="secondary"
          >
            <q-icon name="search" />
          </e-button>
          <CartMenuItem class="cart-button" />
        </div>
        <e-button
          tag="a"
          target="_blank"
          href="/mijnexamenbundel"
          aria-label="Mijn Examenbundel"
          type="primary"
          @click.native="trackEvent()"
        >
          <q-icon name="profile" />
          <span class="hidden ml-2 xl:block">
            Mijn Examenbundel
          </span>
        </e-button>
      </div>
    </div>
  </div>
</template>

<script>

  import { gtmSendEvent } from '@/utils/gtm-send-event'

  import Hamburger from '@/components/Hamburger'
  import CartMenuItem from '@/components/Ecommerce/Cart/CartMenuItem.vue'
  import DesktopNavigation from '@/components/DesktopNavigation'

  export default {
    components: {
      Hamburger,
      CartMenuItem,
      DesktopNavigation
    },
    setup (props, { root }) {
      const trackEvent = () => {
        gtmSendEvent('header-desktop', 'mijn-examenbundel', 'clicked')
      }

      return {
        trackEvent
      }
    }
  }
</script>

<style lang="scss">
header {
  .button-secondary {
    @apply shadow-none;
    @screen lg {
      box-shadow: inset 0 0 0 0.125rem theme("colors.gray.200");
    }
  }
}
</style>

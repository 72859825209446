<template>
  <div class="inline-block relative">
    <span
      v-if="total > 0"
      :class="fontSize"
      class="absolute -right-1 -top-1 flex items-center justify-center w-6 h-6 bg-blue-500 rounded-full font-primary text-white font-bold"
    >
      {{ total }}
    </span>
    <e-button
      tag="nuxt-link"
      to="/winkelwagen"
      aria-label="Winkelwagen"
      type="secondary"
    >
      <q-icon name="cart" />
    </e-button>
  </div>
</template>

<script>
  import { computed } from '@nuxtjs/composition-api'
  import { useCart } from '@propeller-commerce/propeller'
  import { onSSR } from '@vue-storefront/core'

  export default {
    setup () {
      const { load, cart, clear } = useCart()

      const getTotalQuantity = (cartItems) => {
        if (cartItems?.length > 0) {
          return cartItems.reduce((acc, curr) => acc + Number(curr.quantity), 0)
        }
        return 0
      }
      const total = computed(() => getTotalQuantity(cart.value?.items))

      const fontSize = computed(() => {
        return total.value >= 10
          ? total.value >= 100 ? 'text-2xs' : 'text-sm'
          : 'text-base'
      })

      onSSR(async () => {
        try {
          await load()
        } catch (e) {
          if (!cart.value) {
            console.log('No cart, clearing... CartMenuItem')
            await clear()
            await load()
          }
        }
      })

      return {
        fontSize,
        total
      }
    }
  }

</script>

<template>
  <button
    class="hamburger flex"
    aria-label="Mobile menu"
    @click="handleClick"
  >
    <q-icon :name="iconName" />
  </button>
</template>

<script>

  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        menuOpen: state => state.menuOpen
      }),
      iconName () {
        return this.menuOpen ? 'cross' : 'menu'
      }
    },
    methods: {
      handleClick () {
        this.$store.commit('SET_MENU', !this.menuOpen)
      }
    }
  }
</script>

<style lang="scss">

  .hamburger {
    &:focus {
      @apply outline-none;
    }
  }

</style>

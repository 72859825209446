<template>
  <div class="desktop-navigation">
    <nav class="navigation">
      <ul
        v-if="mainNavigation.fields"
        class="flex"
      >
        <li
          v-for="item in mainNavigation.fields.navigationItems"
          :key="item.sys.id"
          class="main-nav-li relative md:mr-4 lg:mr-8"
          @mouseenter="handleMouseEnter(item.sys.id)"
          @mouseleave="handleMouseLeave"
        >
          <a
            v-if="isRelativeUrl(item.fields.link)"
            :href="item.fields.link"
            class="nav-link main-nav-link"
            :class="[
              $route.path.startsWith(item.fields.link, 0) ? 'nuxt-link-active' : '',
              activeSubMenu === item.sys.id ? 'submenu-open' : ''
            ]"
            @click="handleTapToggle(item, $event)"
          >
            {{ item.fields.title }}
            <q-icon
              v-if="item.fields.childItems"
              name="chevron-down"
            />
          </a>
          <a
            v-else
            :href="items.fields.link"
            target="_blank"
          >
            {{ item.fields.title }}
          </a>
          <div v-if="item.fields.childItems">
            <transition name="subnav-transition">
              <div
                v-show="item.fields.childItems && activeSubMenu === item.sys.id"
                class="sub-nav-wrapper"
              >
                <ul class="sub-nav">
                  <li
                    v-for="child in item.fields.childItems"
                    :key="child.sys.id"
                  >
                    <nuxt-link
                      v-if="isRelativeUrl(child.fields.link)"
                      :to="child.fields.link"
                      class="nav-link sub-nav-link hover:text-blue-500 default-transition"
                      @click.native="handleTapToggleSubMenu(item, child)"
                    >
                      {{ child.fields.title }}
                    </nuxt-link>
                    <a
                      v-else
                      :href="child.fields.link"
                      target="_blank"
                    >
                      {{ child.fields.title }}
                    </a>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>

  import { mapState } from 'vuex'
  import isRelativeUrl from '@/mixins/isRelativeUrl'
  import { gtmSendEvent } from '@/utils/gtm-send-event'

  export default {
    mixins: [isRelativeUrl],
    data () {
      return {
        activeSubMenu: null
      }
    },
    computed: {
      ...mapState({
        mainNavigation: state => state.mainNavigation
      })
    },
    methods: {
      handleMouseEnter (id) {
        if (!this.$device.isTouch) {
          this.activeSubMenu = id
        }
      },
      handleMouseLeave () {
        if (!this.$device.isTouch) {
          this.activeSubMenu = null
        }
      },
      handleTapToggle (item, event) {
        event.preventDefault()
        if (this.$device.isTouch && (!this.activeSubMenu || this.activeSubMenu !== item.sys.id) && item.fields.childItems) {
          this.activeSubMenu = item.sys.id
        } else {
          this.activeSubMenu = null
          gtmSendEvent('navigation-desktop', 'click-main-item', item.fields.title)
          this.$router.push(item.fields.link)
        }
      },
      handleTapToggleSubMenu (parent, child) {
        this.activeSubMenu = null
        gtmSendEvent('navigation-desktop', 'click-sub-item', parent.fields.title + ' / ' + child.fields.title)
      }
    }
  }
</script>

<style lang="scss">
.desktop-navigation {
  .nav-link {
    @apply relative flex items-center font-bold;

    &.nuxt-link-active {
      @apply text-blue-500;
    }
  }

  .main-nav-link {
    @apply pt-4;
    padding-bottom: 2.35rem;

    &:after {
      content: "";
      @apply absolute bottom-0 left-0 w-full bg-blue-500;
      height: 0.25rem;
      backface-visibility: hidden;
      transform: scaleX(0);
      transition: 0.25s ease(easeOutSine);
    }

    &:hover:after {
      transform: scaleX(1);
    }

    &.nuxt-link-active {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  .main-nav-li {
    .q-icon {
      transition: transform 0.25s ease(easeOutSine);
    }

    .submenu-open {
      .q-icon {
        transform: rotate(180deg);
      }
      .main-nav-link:after {
        transform: scaleX(1);
      }
    }

    &:hover {
      .main-nav-link:after {
        transform: scaleX(1);
      }
    }
  }

  .sub-nav-wrapper {
    @apply absolute pt-2;
    top: 100%;
    left: 50%;
    margin-left: -9rem;
    width: 18rem;
  }

  .sub-nav {
    @apply bg-white shadow rounded p-6;

    li:not(:last-child) {
      @apply pb-6;
    }
  }

  .subnav-transition-enter-active,
  .subnav-transition-leave-active {
    transition: opacity 0.15s ease(easeOutSine),
      transform 0.2s ease(easeOutQuad);
  }
  .subnav-transition-enter,
  .subnav-transition-leave-to {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
}
</style>
